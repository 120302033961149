import React from 'react';
import { useSelector } from 'react-redux';
import { WelcomeMessage } from './components/welcome-message/WelcomeMessage';
import { UpcomingOrders } from './components/upcoming-orders/UpcomingOrders';
import { RecipeOverview } from './components/recipe-overview/RecipeOverview';
import { Supplements } from './components/supplements/Supplements';
import {
  getDefaultSubscriptionSelector,
  getFlowBannerSelector,
  getFlowBannerTextSelector,
} from '@modules/subscriptions/core/selectors';
import { getCurrentOrderSelector } from '@modules/order-schedule/core/selectors';
import { CustomerTag } from '@modules/dashboard/components/customer-tag/CustomerTag';
import { getCustomerSelector } from '@modules/customer';
import { SubscriptionReactivation } from './components/subscription-reactivation/SubscriptionReactivation';
import { SubscriptionList } from './components/subscription-list/SubscriptionList';
import { SubscriptionResume } from './components/subscription-resume/SubscriptionResume';
import { RewardBanner } from './components/reward-banner/RewardBanner';
import { UpcomingReward } from './components/reward-banner/UpcomingReward';
import { UpcomingRewardFlyout } from './components/reward-banner/UpcomingRewardFlyout';
import { NoRewardBanner } from './components/reward-banner/NoRewardBanner';
import { RafLink } from './components/raf-link/RafLink';
import { useViewport } from '@constants/viewportBreakpoints';

export const DashboardPage = () => {
  const defaultSubscription = useSelector(getDefaultSubscriptionSelector);
  const order = useSelector(getCurrentOrderSelector);
  const customer = useSelector(getCustomerSelector);

  const flowBanner = useSelector(getFlowBannerSelector);
  const isRewarded = flowBanner?.[0]?.isRewardReceived;

  const flowBannerText = useSelector(getFlowBannerTextSelector);

  const decodeHtmlEntities = (str: any) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = str;
    return textArea.value;
  };

  const decodedFlowBannerText = decodeHtmlEntities(flowBannerText);

  const { isXs } = useViewport();

  return (
    <>
      <CustomerTag />
      <div className="dashboard-page w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem] items-center">
        <div className="md:col-span-5 lg:col-span-1">
          <h1 className="dashboard-page-title">
            <span>Willkommen</span> in deinem Pfotenportal
            {customer?.firstName ? `, ${customer?.firstName}` : null}!
          </h1>
        </div>
        <div className="hidden md:block md:col-span-7 lg:col-span-1">
          <RafLink />
        </div>
      </div>

      <div className="dashboard-page-wrapper grid grid-cols-1 md:grid-cols-2 gap-[1.2rem]">
        <div className="w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem]">
          <div className="md:col-span-5 lg:col-span-1">
            <WelcomeMessage />
          </div>
          <div className="relative px-[1rem] md:px-0 md:col-span-7 lg:col-span-1">
            {isRewarded ? (
              <RewardBanner />
            ) : (
              <>
                {!decodedFlowBannerText ||
                decodedFlowBannerText.length === 0 ||
                !decodedFlowBannerText.trim() ? (
                  <NoRewardBanner />
                ) : (
                  <>
                    <UpcomingReward />
                    {isXs && (
                      <UpcomingRewardFlyout
                        isModalOpen={false}
                        toggleModal={function (): void {}}
                        discountPercentage={''}
                        flowBannerText={null}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-full md:col-span-2 md:grid md:grid-cols-12 lg:grid-cols-2 gap-[1.2rem] flex flex-col h-full">
          <div className="flex-grow h-full md:col-span-6 lg:col-span-1">
            <SubscriptionList />
          </div>
          <div className="md:col-span-6 lg:col-span-1">
            <Supplements />
          </div>
        </div>

        {defaultSubscription &&
          (defaultSubscription?.status === 'ACTIVE' ||
            defaultSubscription?.status === 'PAUSED') && (
            <>
              <div className="dashboard-page-recipes md:col-span-1">
                <RecipeOverview />
              </div>
              <div className="dashboard-page-orders md:col-span-1">
                <UpcomingOrders order={order} />
              </div>
            </>
          )}
      </div>

      {defaultSubscription && defaultSubscription?.status === 'CANCELLED' && (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
            <SubscriptionReactivation />
          </div>
        </div>
      )}

      {defaultSubscription && defaultSubscription?.status === 'PAUSED' && (
        <div className="flex flex-col space-y-6">
          <div className="flex flex-col space-y-6 md:flex-row md:space-x-6 md:space-y-0">
            <SubscriptionResume />
          </div>
        </div>
      )}
    </>
  );
};
